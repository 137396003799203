import { useEffect, useState } from 'react'
import { ContentstackFooter as ContentstackFooterProps } from '@Types/contentstack'
import { DXLFooter } from 'composable/components/dxl-footer'
import useContentstack, { Entry } from '../hooks/useContentstack'

const FOOTER_ITEM = 'footer_items'
const FOOTER_ITEM_GROUP = 'footer_item_group'

export const ContentstackFooter = ({ items, privacy_policy }: ContentstackFooterProps) => {
  const [contentList, setContentList] = useState<Entry[]>()
  const { getEntryByUid, isLoading } = useContentstack()

  useEffect(() => {
    getFooterContent(items)
    getFooterByGroup(items)
  }, [])

  const getFooterContent = async (items) => {
    try {
      const itemsResults = items?.map((item) => getEntryByUid(FOOTER_ITEM_GROUP, item.uid))
      const results = await Promise.all(itemsResults)
      setContentList(results)
    } catch (err) {
      console.log('Error processing Footer data', err)
    }
  }

  const getFooterByGroup = (contentList) => {
    const categorized = {}

    // Iterate over the cars array and categorize cars by brand
    contentList.forEach((item) => {
      if (item.label) {
        const { label, title } = item
        if (!categorized[label]) {
          categorized[label] = []
        }
        categorized[label].push(title)
      }
    })
    return categorized
  }
  if (contentList) {
    return <DXLFooter items={contentList} privacy_policy={privacy_policy} />
  }
}
