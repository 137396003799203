import { useState, useEffect } from 'react'
import { ContentstackPageContainer as ContentstackPageContainerProps } from '@Types/contentstack'
import { ContentList } from './content-list'
import useContentstack from '../../hooks/useContentstack'

type PageContentItemsProps = ContentstackPageContainerProps['page_content'][number]

export const ContentstackPageContainer = ({ page_content: items }: ContentstackPageContainerProps) => {
  const [pageContent, setPageContent] = useState<PageContentItemsProps[]>()
  const { getEntryByUid, isLoading } = useContentstack()

  useEffect(() => {
    const getPageContent = async (items: PageContentItemsProps[]) => {
      try {
        const itemsResults = items?.map((item) => getEntryByUid(item._content_type_uid, item.uid))
        const results = (await Promise.all(itemsResults)) as unknown as PageContentItemsProps[]

        const mappedResult = results.map((result) => {
          return {
            ...result,
            _content_type_uid: items.find((item) => item.uid === result.uid)?._content_type_uid,
          }
        })

        setPageContent(mappedResult)
      } catch (err) {
        console.error('Error processing Page Container Content data', err)
      }
    }

    getPageContent(items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ContentList content={pageContent} />
}
