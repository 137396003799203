import React, { useEffect, useState } from 'react'
import { Box, useBreakpointValue } from '@chakra-ui/react'
import {
  ContentstackArticleCard,
  ContentstackArticleCardSlider as ContentstackArticleCardSliderProps,
} from '@Types/contentstack'
import ArticleCardSliderTastic from 'frontastic/tastics/composable/article-card-slider'
import useContentstack from '../hooks/useContentstack'

export const ContentstackArticleCardSlider = ({
  placeholder_id,
  article_item: items,
  container_margin_bottom,
  container_margin_top,
  container_width,
  text_align: container_alignment,
  container_eyebrow,
  container_title,
  container_description,
  heading_alignment,
  title_font_family,
  title_font_size,
  analyticsTrackingData,
  position_navigation_buttons,
}: ContentstackArticleCardSliderProps) => {
  const [contentList, setContentList] = useState<ContentstackArticleCard[]>()
  const { getEntryByUid, isLoading } = useContentstack()
  const is2xl = useBreakpointValue({ base: false, '2xl': true })

  useEffect(() => {
    const getContent = async () => {
      try {
        const itemsResults = items?.map((item) => getEntryByUid('component_article_card', item.uid))
        const results = (await Promise.all(itemsResults)) as unknown as ContentstackArticleCard[]
        setContentList(results)
      } catch (error) {
        console.error('Error processing Article Card Slider data', error)
      }
    }

    getContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      <ArticleCardSliderTastic
        placeholderid={placeholder_id}
        items={contentList}
        containerMarginBottom={container_margin_bottom}
        containerMarginTop={container_margin_top}
        containerSize={container_width}
        containerAlignment={container_alignment}
        containerEyebrow={container_eyebrow}
        containerDescription={container_description}
        containerTitle={container_title}
        headingAlignment={heading_alignment}
        titleFontFamily={title_font_family}
        titleFontSize={title_font_size}
        analyticsTrackingData={analyticsTrackingData}
        placeToShowButtons={is2xl ? position_navigation_buttons : 'inside'}
      />
    </Box>
  )
}
