import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import {
  ContentstackArticleCard,
  ContentstackProductCategoriesSlider as ContentstackProductCategoriesSliderProps,
} from '@Types/contentstack'
import ProductCategoriesSliderTastic from 'frontastic/tastics/composable/product-categories-slider'
import useContentstack from '../hooks/useContentstack'

export const ContentstackProductCategoriesSlider = ({
  children: items,
  container_margin_bottom,
  container_margin_top,
  container_width,
  content_alignment: container_alignment,
  eyebrow,
  container_title,
  container_description,
  heading_content_alignment,
  title_font_family,
  title_font_size,
  analyticsTrackingData,
}: ContentstackProductCategoriesSliderProps) => {
  const [contentList, setContentList] = useState<ContentstackArticleCard[]>()
  const { getEntryByUid, isLoading } = useContentstack()

  useEffect(() => {
    const getContent = async () => {
      try {
        const itemsResults = items?.map((item) => getEntryByUid('component_article_card', item?.uid))
        const results = (await Promise.all(itemsResults)) as unknown as ContentstackArticleCard[]
        setContentList(results)
      } catch (error) {
        console.error('Error processing Article Card Slider data', error)
      }
    }

    getContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      <ProductCategoriesSliderTastic
        items={contentList}
        containerSize={container_width}
        containerAlignment={container_alignment}
        containerEyebrow={eyebrow}
        containerDescription={container_description}
        containerTitle={container_title}
        headingAlignment={heading_content_alignment}
        titleFontFamily={title_font_family}
        titleFontSize={title_font_size}
        containerMarginTop={container_margin_bottom}
        containerMarginBottom={container_margin_top}
        analyticsTrackingData={analyticsTrackingData}
      />
    </Box>
  )
}
