import { Box } from '@chakra-ui/react'
import { useLocale } from 'frontastic/contexts/localeContext'

const GECountrySelector = () => {
  const { country, currency, setCountry } = useLocale()

  console.log('country:', country)

  return (
    <Box>
      <Box id="country-selector" data-ge-shippingswitcher-flag></Box>
    </Box>
  )
}

export default GECountrySelector
