import { useEffect, useState } from 'react'
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Container, Stack, Text } from '@chakra-ui/react'
import {
  ContentstackGenericSideMenuPage as ContentstackGenericSideMenuPageProps,
  ContentstackTextCard as ContentstackTextCardProps,
} from '@Types/contentstack'
import { useFormat } from 'helpers/hooks/useFormat'
import { GenericNav } from './generic-navigation'
import { ContentstackRichText } from './rich-text'
import { useComposable } from '../composable-provider'
import useContentstack from '../hooks/useContentstack'

const PageBreadcrumb = ({ currentTitle }) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { path } = useComposable()

  return (
    <Breadcrumb gap={2} textStyle={{ base: 'body-100', md: 'body-100' }} color={'secondary'}>
      <BreadcrumbItem>
        <BreadcrumbLink href={path.getHome()}>{formatMessage({ id: 'category.breadcrumb.root' })}</BreadcrumbLink>
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink href="#">{currentTitle}</BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  )
}

const GenericLayout = ({ title, sideBarItems }) => {
  return (
    <Container maxW="container.2xl" mx="auto" px={0}>
      <Stack
        id={'header'}
        pt={{ base: 6, md: 16 }}
        px={{ base: 6, md: 24 }}
        pb={{ base: 6, md: 0 }}
        h={{ base: '193px', md: '153px' }}
        justifyContent={'flex-end'}
        alignItems={'flex-start'}
        gap={{ base: 2, md: 3 }}
      >
        <PageBreadcrumb currentTitle={title} />
        <Text textStyle={{ base: 'heading-mobile-500', md: 'heading-desktop-500' }}>{title}</Text>
      </Stack>
      <Stack id={'frame'} flexDirection={'row'} py={0} pl={0} pr={{ base: 0, md: 24 }} gap={6}>
        <Box id={'side-bar'} maxWidth={{ base: 'none', md: '395px' }} w={'100%'}>
          <GenericNav title={title} items={sideBarItems} />
        </Box>
        <Box
          id={'container'}
          maxWidth={'925px'}
          w={'100%'}
          display={{ base: 'none', md: 'flex' }}
          flexDirection={'column'}
          py={12}
          px={0}
          scrollBehavior={'smooth'}
        >
          {sideBarItems?.map(({ uid, slug, title, data }) => {
            return (
              <Box id={slug} key={`rich_text_container_${uid}`}>
                <ContentstackRichText key={`rich_text_${slug}`} {...data} styles={{ px: 0, py: 0 }} />
              </Box>
            )
          })}
        </Box>
      </Stack>
    </Container>
  )
}

export const ContentstackGenericSideMenuPage = ({ sections, title }: ContentstackGenericSideMenuPageProps) => {
  const [contentList, setContentList] = useState([])
  const { getEntryByUid, isLoading } = useContentstack()
  useEffect(() => {
    getSectionsContent(sections)
  }, [])

  const getSectionsContent = async (sections) => {
    try {
      const itemsResults = sections?.map(async (eachContent) => {
        return {
          contentType: eachContent._content_type_uid,
          data: await getEntryByUid(eachContent._content_type_uid, eachContent.uid),
        }
      })
      const results = await Promise.all(itemsResults)
      setContentList(results)
    } catch (err) {
      console.log('Error processing Mega Menu data', err)
    }
  }

  if (!sections || sections.length === 0) return null

  const sideBarItems = contentList.map((section) => {
    return {
      uid: section?.data?.uid,
      slug: section?.data?.slug,
      title: section?.data?.title,
      data: section?.data,
    }
  })

  return <GenericLayout title={title} sideBarItems={sideBarItems} />
}
